const screens = {
  autoDecline: {
    styles:
      " #hv_form_v2_fhVqH7 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n #hv_form_v2_82G6RQ {\r\n    padding-top: 24px;\r\n}\r\n #hv_form_v2_1mUYRD {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n #hv_form_v2_l6KcTF {\r\n    max-height: 32px;\r\n}\r\n #hv_form_v2_63s0xB {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 24px;*/\r\n}\r\n #hv_form_v2_3WG3gN {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n #hv_form_v2_atNFFp {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n #hv_form_v2_tT2Ykh {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n #hv_form_v2_h1pYgB {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n #hv_form_v2_LmSL6h {\r\n    margin-top: 20px;\r\n}\r\n",
    scripts: [
      "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/cred-component-lib-v1.js",
    ],
    content:
      '<div id="hv_form_v2_fhVqH7"><div id="hv_form_v2_82G6RQ"><div id="hv_form_v2_1mUYRD"><img id="hv_form_v2_l6KcTF"></div></div><div id="hv_form_v2_63s0xB"><div id="hv_form_v2_3WG3gN"><img id="hv_form_v2_LvvmrW"></div><div id="hv_form_v2_atNFFp"><cred-main-label id="hv_form_v2_42NAlz"></cred-main-label></div><div id="hv_form_v2_tT2Ykh"><div id="hv_form_v2_h1pYgB"><cred-subtitle-text id="hv_form_v2_vroIS6"></cred-subtitle-text></div></div><div id="hv_form_v2_LmSL6h"></div></div></div>',
    stylesheets: [],
    componentConfigs: {
      hv_form_v2_fhVqH7: {
        css: "#hv_form_v2_fhVqH7 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_82G6RQ: {
        css: "#hv_form_v2_82G6RQ {\r\n    padding-top: 24px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_1mUYRD: {
        css: "#hv_form_v2_1mUYRD {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_l6KcTF: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/logo_1dab0ca8-f800-4d25-8284-9f61558c3793.png",
        },
        css: "#hv_form_v2_l6KcTF {\r\n    max-height: 32px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_63s0xB: {
        css: "#hv_form_v2_63s0xB {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 24px;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_3WG3gN: {
        css: "#hv_form_v2_3WG3gN {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_LvvmrW: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/unable_to_verify_details_icon_(80x60).png",
          width: "80",
        },
        events: {},
      },
      hv_form_v2_atNFFp: {
        css: "#hv_form_v2_atNFFp {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_42NAlz: {
        attributes: {
          text: "we were unable to  complete your KYC",
          color: "#0D0D0D",
          "font-family": "Gilroy",
          "font-size": "1.2em",
          "font-weight": "700",
        },
        events: {},
      },
      hv_form_v2_tT2Ykh: {
        events: {},
        css: "#hv_form_v2_tT2Ykh {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n",
      },
      hv_form_v2_h1pYgB: {
        css: "#hv_form_v2_h1pYgB {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_vroIS6: {
        attributes: {
          text: "this happened as we were unable to verify all of your KYC details. If you believe your details are correct, you can re-try with Digilocker or Aadhaar KYC.",
          "font-color": "#0D0D0D",
        },
        events: {},
      },
      hv_form_v2_LmSL6h: {
        css: "#hv_form_v2_LmSL6h {\r\n    margin-top: 20px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_p19UrV: {
        attributes: {
          label: "Exit",
          shadow: "0px 0px",
        },
        events: {
          click: {
            nextStep: "approve",
          },
        },
      },
    },
  },
  autoApproved: {
    styles:
      " #hv_form_v2_ciLfa8 {\r\n}\r\n #hv_form_v2_SPDtKc {\r\n    padding-top: 20px;\r\n}\r\n #hv_form_v2_iDCLz6 {\r\n    background-color: black;\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    padding: 24px 24px 24px 24px;\r\n    justify-content: space-between;\r\n}\r\n #hv_form_v2_TpS5AH {\r\n    padding-top: 12px;\r\n}\r\n #hv_form_v2_VME1s4 {\r\n    padding: 28px 2px 2px 0px;\r\n}\r\n #hv_form_v2_Dhdnfc {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-end;\r\n}\r\n #hv_form_v2_EiU9ee {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n #hv_form_v2_rVyNjX {\r\n    max-height: 32px;\r\n}\r\n",
    scripts: [],
    content:
      '<div id="hv_form_v2_iDCLz6"><div id="hv_form_v2_EiU9ee"><img id="hv_form_v2_rVyNjX"></div><div id="hv_form_v2_Dhdnfc"><div id="hv_form_v2_tdAQMM"><img id="hv_form_v2_ciLfa8"></div><div id="hv_form_v2_SPDtKc"><cred-main-label id="hv_form_v2_GVrc4c"></cred-main-label></div><div id="hv_form_v2_TpS5AH"><cred-subtitle-text id="hv_form_v2_vgOk44"></cred-subtitle-text></div><div id="hv_form_v2_VME1s4"><cred-secondary-button id="hv_form_v2_6dNgZU"></cred-secondary-button></div></div></div>',
    stylesheets: [],
    componentConfigs: {
      hv_form_v2_tdAQMM: {
        events: {},
      },
      hv_form_v2_ciLfa8: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/kyc_successful_icon_(100x71).png",
          width: "100",
        },
        events: {},
        css: "#hv_form_v2_ciLfa8 {\r\n}\r\n",
      },
      hv_form_v2_SPDtKc: {
        css: "#hv_form_v2_SPDtKc {\r\n    padding-top: 20px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_GVrc4c: {
        attributes: {
          text: "the KYC has been successfully completed",
        },
        events: {},
      },
      hv_form_v2_iDCLz6: {
        css: "#hv_form_v2_iDCLz6 {\r\n    background-color: black;\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    padding: 24px 24px 24px 24px;\r\n    justify-content: space-between;\r\n}\r\n",
      },
      hv_form_v2_TpS5AH: {
        css: "#hv_form_v2_TpS5AH {\r\n    padding-top: 12px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_vgOk44: {
        attributes: {
          text: "you can now receive money from your tenant. let them know by sending a message",
          "font-size": "1em",
        },
        events: {},
      },
      hv_form_v2_VME1s4: {
        css: "#hv_form_v2_VME1s4 {\r\n    padding: 28px 2px 2px 0px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_6dNgZU: {
        attributes: {
          "image-url":
            "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/whatsapp_image_icon_(20x20).png",
          shadow: "2px 2px grey",
          label: "Tell them on whatsapp →",
        },
        events: {
          click: {
            nextStep: "approve",
          },
        },
      },
      hv_form_v2_Dhdnfc: {
        css: "#hv_form_v2_Dhdnfc {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-end;\r\n}\r\n",
      },
      hv_form_v2_EiU9ee: {
        css: "#hv_form_v2_EiU9ee {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_rVyNjX: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/logo_1dab0ca8-f800-4d25-8284-9f61558c3793.png",
        },
        css: "#hv_form_v2_rVyNjX {\r\n    max-height: 32px;\r\n}\r\n",
        events: {},
      },
    },
  },
  linkExpired: {
    styles:
      " #hv_form_v2_JGJnWY {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    padding: 24px;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n #hv_form_v2_9iK4c8 {\r\n    padding-top: 20px;\r\n    width: 70%;\r\n}\r\n #hv_form_v2_Uq9Zhh {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n #hv_form_v2_H5rovW {\r\n    max-height: 32px;\r\n}\r\n",
    scripts: [],
    content:
      '<div id="hv_form_v2_JGJnWY"><div id="hv_form_v2_Uq9Zhh"><img id="hv_form_v2_H5rovW"></div><div id="hv_form_v2_JhldFj"><div id="hv_form_v2_NVh6fe"><img id="hv_form_v2_eVAZut"></div><div id="hv_form_v2_pIxJdw"><cred-main-label id="hv_form_v2_WkXYjO"></cred-main-label></div><div id="hv_form_v2_9iK4c8"><cred-subtitle-text id="hv_form_v2_lFZso8"></cred-subtitle-text></div></div></div>',
    stylesheets: [],
    componentConfigs: {
      hv_form_v2_NVh6fe: {
        events: {},
      },
      hv_form_v2_eVAZut: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/Link_expired_new_(78x58).png",
          width: "100",
        },
        events: {},
      },
      hv_form_v2_JGJnWY: {
        css: "#hv_form_v2_JGJnWY {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    padding: 24px;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n",
      },
      hv_form_v2_pIxJdw: {
        events: {},
      },
      hv_form_v2_WkXYjO: {
        attributes: {
          text: "the KYC link and payment request has expired",
          "font-size": "1.7em",
        },
        events: {},
      },
      hv_form_v2_9iK4c8: {
        css: "#hv_form_v2_9iK4c8 {\r\n    padding-top: 20px;\r\n    width: 70%;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_lFZso8: {
        attributes: {
          text: "you can request the sender to share a new link",
          "font-size": "1em",
        },
        events: {
          click: {
            nextStep: "approve",
          },
        },
      },
      hv_form_v2_Uq9Zhh: {
        css: "#hv_form_v2_Uq9Zhh {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_H5rovW: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/logo_1dab0ca8-f800-4d25-8284-9f61558c3793.png",
        },
        css: "#hv_form_v2_H5rovW {\r\n    max-height: 32px;\r\n}\r\n",
        events: {},
      },
    },
  },
  needsReview: {
    styles:
      " #hv_form_v2_fhVqH7 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n #hv_form_v2_82G6RQ {\r\n    padding-top: 24px;\r\n}\r\n #hv_form_v2_1mUYRD {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n #hv_form_v2_l6KcTF {\r\n    max-height: 32px;\r\n}\r\n #hv_form_v2_63s0xB {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 24px;*/\r\n}\r\n #hv_form_v2_3WG3gN {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n #hv_form_v2_atNFFp {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n #hv_form_v2_tT2Ykh {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n #hv_form_v2_h1pYgB {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n #hv_form_v2_LmSL6h {\r\n    margin-top: 20px;\r\n}\r\n",
    scripts: [
      "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/cred-component-lib-v1.js",
    ],
    content:
      '<div id="hv_form_v2_fhVqH7"><div id="hv_form_v2_82G6RQ"><div id="hv_form_v2_1mUYRD"><img id="hv_form_v2_l6KcTF"></div></div><div id="hv_form_v2_63s0xB"><div id="hv_form_v2_3WG3gN"><img id="hv_form_v2_LvvmrW"></div><div id="hv_form_v2_atNFFp"><cred-main-label id="hv_form_v2_42NAlz"></cred-main-label></div><div id="hv_form_v2_tT2Ykh"><div id="hv_form_v2_h1pYgB"><cred-subtitle-text id="hv_form_v2_vroIS6"></cred-subtitle-text></div></div><div id="hv_form_v2_LmSL6h"></div></div></div>',
    stylesheets: [],
    componentConfigs: {
      hv_form_v2_fhVqH7: {
        css: "#hv_form_v2_fhVqH7 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_82G6RQ: {
        css: "#hv_form_v2_82G6RQ {\r\n    padding-top: 24px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_1mUYRD: {
        css: "#hv_form_v2_1mUYRD {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_l6KcTF: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/logo_1dab0ca8-f800-4d25-8284-9f61558c3793.png",
        },
        css: "#hv_form_v2_l6KcTF {\r\n    max-height: 32px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_63s0xB: {
        css: "#hv_form_v2_63s0xB {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 24px;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_3WG3gN: {
        css: "#hv_form_v2_3WG3gN {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_LvvmrW: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/unable_to_verify_details_icon_(80x60).png",
          width: "80",
        },
        events: {},
      },
      hv_form_v2_atNFFp: {
        css: "#hv_form_v2_atNFFp {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_42NAlz: {
        attributes: {
          text: "we were unable to  complete your KYC",
          color: "#0D0D0D",
          "font-family": "Gilroy",
          "font-size": "1.2em",
          "font-weight": "700",
        },
        events: {},
      },
      hv_form_v2_tT2Ykh: {
        events: {},
        css: "#hv_form_v2_tT2Ykh {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n",
      },
      hv_form_v2_h1pYgB: {
        css: "#hv_form_v2_h1pYgB {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_vroIS6: {
        attributes: {
          text: "this happened as we were unable to verify all of your KYC details. If you believe your details are correct, you can re-try with Digilocker or Aadhaar KYC.",
          "font-color": "#0D0D0D",
        },
        events: {},
      },
      hv_form_v2_LmSL6h: {
        css: "#hv_form_v2_LmSL6h {\r\n    margin-top: 20px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_p19UrV: {
        attributes: {
          label: "Exit",
          shadow: "0px 0px",
        },
        events: {
          click: {
            nextStep: "approve",
          },
        },
      },
    },
  },
  userCancelled: {
    styles:
      " #hv_form_v2_MLUhv2 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n #hv_form_v2_BMHZ0C {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n #hv_form_v2_kbKVY2 {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n #hv_form_v2_zQz4vS {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n #hv_form_v2_uIFFjC {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 20px;*/\r\n}\r\n #hv_form_v2_p1LTCQ {\r\n    margin-top: 20px;\r\n}\r\n #hv_form_v2_6FuxrO {\r\n    margin-top: 20px;\r\n}\r\n #hv_form_v2_oUZQhY {\r\n    max-height: 32px;\r\n}\r\n #hv_form_v2_aq4ASS {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n #hv_form_v2_4eYRZx {\r\n    padding-top: 24px;\r\n}\r\n #hv_form_v2_qPM1Wd {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n",
    scripts: [
      "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/cred-component-lib-v1.js",
    ],
    content:
      '<div id="hv_form_v2_MLUhv2"><div id="hv_form_v2_4eYRZx"><div id="hv_form_v2_aq4ASS"><img id="hv_form_v2_oUZQhY"></div></div><div id="hv_form_v2_uIFFjC"><div id="hv_form_v2_BMHZ0C"><img id="hv_form_v2_0O4QiC"></div><div id="hv_form_v2_qPM1Wd"><cred-main-label id="hv_form_v2_0i4oOL"></cred-main-label></div><div id="hv_form_v2_kbKVY2"><div id="hv_form_v2_zQz4vS"><cred-subtitle-text id="hv_form_v2_d1oCY4"></cred-subtitle-text></div></div><div id="hv_form_v2_p1LTCQ"><cred-primary-button id="hv_form_v2_zvMimL"></cred-primary-button></div><div id="hv_form_v2_6FuxrO"></div></div></div>',
    stylesheets: [],
    componentConfigs: {
      hv_form_v2_MLUhv2: {
        css: "#hv_form_v2_MLUhv2 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n",
      },
      hv_form_v2_BMHZ0C: {
        css: "#hv_form_v2_BMHZ0C {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_0O4QiC: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/unable_to_verify_details_icon_(80x60).png",
          width: "80",
        },
        events: {},
      },
      hv_form_v2_kbKVY2: {
        events: {},
        css: "#hv_form_v2_kbKVY2 {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n",
      },
      hv_form_v2_zQz4vS: {
        css: "#hv_form_v2_zQz4vS {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_d1oCY4: {
        attributes: {
          text: `this happened as the journey was not completed successfully. but, here's the good news, you can try again right now.`,
          "font-color": "#0D0D0D",
        },
        events: {},
      },
      hv_form_v2_uIFFjC: {
        css: "#hv_form_v2_uIFFjC {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 20px;*/\r\n}\r\n",
      },
      hv_form_v2_p1LTCQ: {
        css: "#hv_form_v2_p1LTCQ {\r\n    margin-top: 20px;\r\n}\r\n",
        events: {},
      },

      hv_form_v2_zvMimL: {
        attributes: {
          label: `Try again now`,
          shadow: "0px 0px",
        },
        events: {
          click: {
            nextStep: "approve",
          },
        },
      },
      hv_form_v2_6FuxrO: {
        css: "#hv_form_v2_6FuxrO {\r\n    margin-top: 20px;\r\n}\r\n",
        events: {},
      },

      hv_form_v2_4gCRUY: {
        attributes: {
          label: `I'll do it later`,
          shadow: "0px 0px",
        },
        events: {},
      },

      hv_form_v2_oUZQhY: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/logo_1dab0ca8-f800-4d25-8284-9f61558c3793.png",
        },
        css: "#hv_form_v2_oUZQhY {\r\n    max-height: 32px;\r\n}\r\n",
      },
      hv_form_v2_aq4ASS: {
        css: "#hv_form_v2_aq4ASS {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n",
      },
      hv_form_v2_4eYRZx: {
        css: "#hv_form_v2_4eYRZx {\r\n    padding-top: 24px;\r\n}\r\n",
      },
      hv_form_v2_qPM1Wd: {
        css: "#hv_form_v2_qPM1Wd {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_0i4oOL: {
        attributes: {
          text: `we're unable to  verify your details right now`,
          color: "#0D0D0D",
          "font-family": "Gilroy",
          "font-size": "1.2em",
          "font-weight": "700",
        },
        events: {},
      },
    },
  },
  error: {
    styles:
      " #hv_form_v2_fhVqH7 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n #hv_form_v2_82G6RQ {\r\n    padding-top: 24px;\r\n}\r\n #hv_form_v2_1mUYRD {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n #hv_form_v2_l6KcTF {\r\n    max-height: 32px;\r\n}\r\n #hv_form_v2_63s0xB {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 24px;*/\r\n}\r\n #hv_form_v2_3WG3gN {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n #hv_form_v2_atNFFp {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n #hv_form_v2_tT2Ykh {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n #hv_form_v2_h1pYgB {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n #hv_form_v2_LmSL6h {\r\n    margin-top: 20px;\r\n}\r\n",
    scripts: [
      "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/cred-component-lib-v1.js",
    ],
    content:
      '<div id="hv_form_v2_fhVqH7"><div id="hv_form_v2_82G6RQ"><div id="hv_form_v2_1mUYRD"><img id="hv_form_v2_l6KcTF"></div></div><div id="hv_form_v2_63s0xB"><div id="hv_form_v2_3WG3gN"><img id="hv_form_v2_LvvmrW"></div><div id="hv_form_v2_atNFFp"><cred-main-label id="hv_form_v2_42NAlz"></cred-main-label></div><div id="hv_form_v2_tT2Ykh"><div id="hv_form_v2_h1pYgB"><cred-subtitle-text id="hv_form_v2_vroIS6"></cred-subtitle-text></div></div><div id="hv_form_v2_LmSL6h"></div></div></div>',
    stylesheets: [],
    componentConfigs: {
      hv_form_v2_fhVqH7: {
        css: "#hv_form_v2_fhVqH7 {\r\n    display: flex;\r\n    flex-direction: column;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n    background-color: black;\r\n    justify-content: space-between;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_82G6RQ: {
        css: "#hv_form_v2_82G6RQ {\r\n    padding-top: 24px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_1mUYRD: {
        css: "#hv_form_v2_1mUYRD {\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_l6KcTF: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/logo_1dab0ca8-f800-4d25-8284-9f61558c3793.png",
        },
        css: "#hv_form_v2_l6KcTF {\r\n    max-height: 32px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_63s0xB: {
        css: "#hv_form_v2_63s0xB {\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-radius: 5px;\r\n    box-sizing: border-box;\r\n    width: 100%;\r\n    padding: 24px;\r\n    background-color: white;\r\n    /*\r\n    gap: 24px;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_3WG3gN: {
        css: "#hv_form_v2_3WG3gN {\r\n    /*\r\n    padding: 5% 0% 0% 5%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_LvvmrW: {
        attributes: {
          src: "https://hv-camera-web-sg.s3.ap-southeast-1.amazonaws.com/client-assets/Cred/images/technical_issue_icon_(80x60).png",
          width: "80",
        },
        events: {},
      },
      hv_form_v2_atNFFp: {
        css: "#hv_form_v2_atNFFp {\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    padding-left: 16px;*/\r\n    width: 60%;\r\n    /*\r\n    border-radius: 5px;*/\r\n    margin-top: 4px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_42NAlz: {
        attributes: {
          text: "there seems to be a technical issue",
          color: "#0D0D0D",
          "font-family": "Gilroy",
          "font-size": "1.2em",
          "font-weight": "700",
        },
        events: {},
      },
      hv_form_v2_tT2Ykh: {
        events: {},
        css: "#hv_form_v2_tT2Ykh {\r\n    width: 80%;\r\n    margin-top: 20px;\r\n}\r\n",
      },
      hv_form_v2_h1pYgB: {
        css: "#hv_form_v2_h1pYgB {\r\n    /*\r\n    padding-left: 6%;*/\r\n    /*\r\n    margin-top: 24px;*/\r\n    /*\r\n    background-color: white;*/\r\n    /*\r\n    width: 100%;*/\r\n}\r\n",
        events: {},
      },
      hv_form_v2_vroIS6: {
        attributes: {
          text: "that’s very rare & unfortunate - this happened due to unforeseen technical issues. however, you can try again later.",
          "font-color": "#0D0D0D",
        },
        events: {},
      },
      hv_form_v2_LmSL6h: {
        css: "#hv_form_v2_LmSL6h {\r\n    margin-top: 20px;\r\n}\r\n",
        events: {},
      },
      hv_form_v2_p19UrV: {
        attributes: {
          label: "Try again later",
          shadow: "0px 0px",
        },
        events: {
          click: {
            nextStep: "approve",
          },
        },
      },
    },
  },
};

export default screens;
