import shareMessage from "./shareMessage";
import getCookie from "./getCookie";
import redirectToKYC from "./redirectToKYC";
import changeElementStyles from "./changeElementStyles";



const checkStatusAndSetRedirect = (status) => {
  if (status === "user_cancelled") {
    const elementId = "hv_form_v2_zvMimL";
    const button = document.getElementById(elementId);
    if (button) {
      const cookieValue = getCookie("hvLinkKycUrl");
      if (cookieValue) {
        button.addEventListener("click", () => redirectToKYC(elementId, cookieValue));
      } else {
        const subtitleText = document.getElementById("hv_form_v2_d1oCY4");
        const text = "this happened as the journey was not completed successfully. but, here's the good news, you can try again right now by re-opening the link.";
        if (subtitleText) {
          subtitleText.setAttribute("text", text);
          button.style.display = "none";
        }
      }
    }
  } else if (status === "auto_approved" || status === "invalid_auto_approved" || status === "approve") {
    const button = document.getElementById("hv_form_v2_6dNgZU")
    if(button){
      button.addEventListener("click", shareMessage)
    }
  } else if (status === "expired" || status === "invalid_started" || status === "invalid_expired") {
    changeElementStyles(status);
  }
};

export default checkStatusAndSetRedirect;
