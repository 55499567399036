const changeElementStyles = (status) => {
    if(status === "expired" || status === "invalid_started" || status === "invalid_expired"){
        const iconContainer = document.getElementById("hv_form_v2_NVh6fe");
        const subtextContainer = document.getElementById("hv_form_v2_9iK4c8");
        if(iconContainer && subtextContainer){
            iconContainer.style.padding = "18px 0px 18px";
            subtextContainer.style.padding = "10px 0px 20px";
        }
    }
  }

export default changeElementStyles;