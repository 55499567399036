import React from "react";
import "./Footer.scss";
import PropTypes from "prop-types";

const Footer = ({ darkMode }) => {
  const footerId = `footer__item_container`;
  const newShieldIcon =
    "https://hv-camera-web-sg.s3-ap-southeast-1.amazonaws.com/images/gkycShield.png";

  let imgStyle = {};
    imgStyle = {
      height: "11.2px",
      width: "9.3px",
  };

  return (
    <div id={footerId}>
      <img src={newShieldIcon} style={imgStyle} />
      <span className={darkMode ? "light_font":""}>
        powered by HyperVerge
      </span>
    </div>
  );
};

export default Footer;

Footer.propTypes = {
  darkMode: PropTypes.bool,
};
