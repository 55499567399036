import './NotFound.scss';

const NotFound = () => {
  return (
    <div className='wrapper'>
      <p>Not Found</p>
    </div>
  );
};

export default NotFound;
