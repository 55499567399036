import screens from "../data/screens";

const { autoApproved, autoDecline, needsReview, linkExpired,userCancelled,error } = screens;

const getComponentProperties = (status) => {
    const responseObject = {
        properties: null,
        darkMode: false
    };

    switch(status) {
        case "approve":
        case "auto_approved":
        case "invalid_auto_approved":
            responseObject.properties = autoApproved;
            responseObject.darkMode = true;
            break;
        case "decline":
        case "auto_declined":
        case "invalid_auto_declined":
            responseObject.properties = autoDecline;
            responseObject.darkMode = false;
            break;
        case "needs_review":
        case "manualReview":
        case "invalid_needs_review":
            responseObject.properties = needsReview;
            responseObject.darkMode = false;
            break;
        case "user_cancelled":
            responseObject.properties = userCancelled;
            responseObject.darkMode = false;
            break;
        case "error":
            responseObject.properties = error;
            responseObject.darkMode = false;
            break;
        case "expired":
        case "invalid_started":
        case "invalid_expired":
            responseObject.properties = linkExpired;
            responseObject.darkMode = true;
            break;
        default:
            return null;
    }

    return responseObject;
};

export default getComponentProperties;