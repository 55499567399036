import Container from "./containers/Container";
import "./App.scss";

const App = ()=>{
  return (
    <div className='app'>
      <Container />
    </div>
  );
}

export default App;
