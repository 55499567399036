import React, { useEffect,useState } from "react";
import "./container.scss";
import "../components/components.scss";
import getComponentProperties from "../utils/getProperty";
import NotFound from "../components/NotFound/body";
import  checkStatusAndSetRedirect  from "../utils/checkStatusAndSetRedirect";
import Footer from "../components/Footer/Footer";



const Container = () => {
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const status = queryParams.get("status");
  const [createdComponent, setCreatedComponent] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [invalidStatus, setInvalidStatus] = useState(false);
  
  const create = (properties, parentDivId) => {
    setCreatedComponent(false);
    const htmlContent = properties.content;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const componentConfigs = properties.componentConfigs;
    
    // Attach styles
    const styleElement = document.createElement("style");
    styleElement.textContent = properties.styles;
    document.head.appendChild(styleElement);
    
    (Object.keys(componentConfigs)).forEach((domId) => {
        const { attributes, properties } = componentConfigs[domId];
        const element = doc.getElementById(domId);
        if (element) {
            if (attributes) {
                Object.keys(attributes).forEach((attribute) => {
                  if(attribute){
                    element.setAttribute(attribute, attributes[attribute]);
                  }
                });
            }
            if (properties) {
                Object.keys(properties).forEach((property) => {
                    element[property] = properties[property];
                });
            }
        }
    });
    const div = document.getElementById(parentDivId);
    while (div.firstChild) { div.removeChild(div.firstChild); }
    Array.from(doc.body.childNodes).forEach(function(node) {
        div.appendChild(node);
    });
    setCreatedComponent(true);
};

useEffect(() => {
  try{
    const { properties, darkMode } = getComponentProperties(status);
    setIsDarkMode(darkMode);
    if(properties){
      create(properties, "response_modal__container");
      checkStatusAndSetRedirect(status);
    }else{
      setInvalidStatus(true);
    }
  }catch(e){
    setInvalidStatus(true);
  }
},[status]);

  return (
    <div id="kyc_page">
      <div id="kyc_container">
        <div id="response_modal__container">
          {createdComponent && <Footer darkMode={isDarkMode} />}
          {invalidStatus && <NotFound />}
        </div>
      </div>
    </div>
  );

};

export default Container;