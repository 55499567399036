const shareMessage = async () => {
    const shareData = {
      title: "CRED",
      text: "I have completed the KYC for my account. I can now receive rent and education payments through CRED.",
    };
    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (err) {
        console.log("Something went wrong");
      }
    } else {
        alert("Web Share is not enabled on your browser!");
    }
  };

export default shareMessage;
